import { createApp } from 'vue'; // Correct import for Vue 3
import App from './App.vue';
import router from './router';
import axios from 'axios';
import store from './store'; // Correct import for the Vuex store

// Import main CSS
import '/index.css';

// Set Axios base URL from environment variable
axios.defaults.baseURL = process.env.VUE_APP_APIURL;

// Request interceptor to set the Authorization header dynamically
axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    // Set isLoading to true before the request is sent
    store.commit('setLoading', true);

    return config;
}, (error) => {
    // Set isLoading to false if the request fails
    store.commit('setLoading', false);
    return Promise.reject(error);
});

// Response interceptor to handle loading state and errors
axios.interceptors.response.use(response => {
  // Set isLoading to false after the response is received
  store.commit('setLoading', false);

  // Just return the response if it's successful
  return response;
}, error => {
  // Set isLoading to false if the response fails
  store.commit('setLoading', false);

  // Check if there's a token in localStorage
  const token = localStorage.getItem('token');
  
  // Check if the response is a 401 unauthenticated error and if there's a token
  if (token && error.response && error.response.status === 401) {
    // Remove the token and redirect to login
    localStorage.removeItem('token');
    router.push({ name: 'login' });
    window.location.reload();
  }

  return Promise.reject(error);
});

// Create and mount the Vue application
createApp(App).use(store).use(router).mount('#app');
