<template>
  <div class="p-2 min-h-screen flex flex-col">
    <div class="flex flex-wrap flex-1">
      <!-- Left Pane -->
      <div class="w-full lg:w-2/5 pr-2 flex flex-col">

        <!-- Company Information -->
        <div class="rounded-lg mb-2 p-2 pb-4 border border-gray-300 shadow-md">
          <div class="flex justify-center mb-4">
            <div class="w-full py-1 text-left px-4 font-bold text-orange-500 text-sm">
              Company Name, LLC
            </div>
          </div>
          <div class="flex">
            <!-- Left Column -->
            <div class="w-1/2 pr-4 pl-4"> <!-- Left padding added -->
              <div class="mb-2 text-xs flex items-center whitespace-nowrap">
                <div class="font-bold text-gray-700">Account #:</div>
                <div class="text-black-500 ml-2">123456</div>
              </div>
              <div class="mb-2 text-xs">
                <div class="font-bold text-gray-700">Primary Contact & Service Address:</div>
                <div class="text-black-500">First Last</div>
                <div class="text-black-500">1234 Street Name</div>
                <div class="text-black-500">City, State, ZIP</div>
              </div>
            </div>

            <!-- Divider -->
            <div class="w-px bg-gray-200"></div>

            <!-- Right Column -->
            <div class="w-1/2 pl-6"> <!-- Left padding added -->
              <div class="mb-2 text-xs flex items-center whitespace-nowrap">
                <div class="font-bold text-gray-700">Telephone Access Pin:</div>
                <div class="text-black-500 ml-2">4862</div>
              </div>
              <div class="mb-2 text-xs flex items-center whitespace-nowrap">
                <div class="font-bold text-gray-700">Website:</div>
                <div class="text-black-500 ml-2"><a href="#" class="text-black-500 underline">example.com</a></div>
              </div>
              <div class="mb-2 text-xs flex items-center whitespace-nowrap">
                <div class="font-bold text-gray-700">Email:</div>
                <div class="text-black-500 ml-2"><a href="mailto:email@example.com" class="text-black-500 underline">email@example.com</a></div>
              </div>
              <div class="mb-2 text-xs flex items-center whitespace-nowrap">
                <div class="font-bold text-gray-700">Phone:</div>
                <div class="text-black-500 ml-2">(123) 456-7890</div>
              </div>
            </div>
          </div>
        </div>


        <!-- Service Plan -->
        <div class="rounded-lg flex-grow border border-gray-300 shadow-md">
          <div class="p-2"> <!-- Increased padding for better spacing -->
            <div class="flex justify-center mb-4">
              <div class="w-full py-1 text-left px-4 font-bold text-orange-500 text-sm">
                Subscription Services: <span class="text-black">Telecom Elite</span>
              </div>
            </div>
            <div class="flex">
              <!-- Left Column -->
              <div class="w-1/2 pr-4 pl-4"> <!-- Added left padding -->
                <div class="mb-2 text-xs">
                  <div class="font-bold text-gray-700">Toll-Free Inbound Calls:</div>
                  <div class="text-black-500">$0.10 / minute</div>
                </div>
                <div class="mb-2 text-xs">
                  <div class="font-bold text-gray-700">Local Inbound / Outbound Calls:</div>
                  <div class="text-black-500">Unlimited</div>
                </div>
                <div class="mb-2 text-xs">
                  <div class="font-bold text-gray-700">Phone Users:</div>
                  <div class="text-black-500">10 ($15 / user)</div>
                </div>
                <div class="mb-2 text-xs">
                  <div class="font-bold text-gray-700">Extensions:</div>
                  <div class="text-black-500">5 (Included)</div>
                </div>
                <div class="mb-2 text-xs">
                  <div class="font-bold text-gray-700">Call Groups:</div>
                  <div class="text-black-500">2 ($5 / Group)</div>
                </div>
                <div class="mb-2 text-xs">
                  <div class="font-bold text-gray-700">Voice Mailboxes:</div>
                  <div class="text-black-500">5 (Included)</div>
                </div>
                <div class="mb-2 text-xs">
                  <div class="font-bold text-gray-700">Forwarding Contacts:</div>
                  <div class="text-black-500">3 (Included)</div>
                </div>
                <div class="mb-2 text-xs">
                  <div class="font-bold text-gray-700">IVR Menus:</div>
                  <div class="text-black-500">1 ($10 / menu)</div>
                </div>
              </div>

              <!-- Divider -->
              <div class="w-px bg-gray-200"></div>

              <!-- Right Column -->
              <div class="w-1/2 pl-6"> <!-- Added left padding -->
                <div class="mb-2 text-xs">
                  <div class="font-bold text-gray-700">Call Recordings:</div>
                  <div class="text-black-500">2 ($5 / User)</div>
                </div>
                <div class="mb-2 text-xs">
                  <div class="font-bold text-gray-700">Local Presence:</div>
                  <div class="text-black-500">1 ($10 / User)</div>
                </div>
                <div class="mb-2 text-xs">
                  <div class="font-bold text-gray-700">Local DID's:</div>
                  <div class="text-black-500">3 ($2 / Number)</div>
                </div>
                <div class="mb-2 text-xs">
                  <div class="font-bold text-gray-700">Toll-Free Numbers:</div>
                  <div class="text-black-500">1 ($5 / Number)</div>
                </div>
                <div class="mb-2 text-xs">
                  <div class="font-bold text-gray-700">Sub-Total (this cycle):</div>
                  <div class="text-black-500">$150.00</div>
                </div>
                <div class="mb-2 text-xs">
                  <div class="font-bold text-gray-700">Previous Balance:</div>
                  <div class="text-black-500">$50.00</div>
                </div>
                <div class="mb-2 text-xs">
                  <div class="font-bold text-gray-700">Credits:</div>
                  <div class="text-black-500">-$10.00</div>
                </div>
              </div>
            </div>
            <div class="mt-4 flex items-start px-2">
              <div class="text-gray-500 text-xs flex w-1/2"> <!-- Very small text for the verbiage -->
                *Balance Due does not include taxes, fees or surcharges that may be applied on date of billing.
              </div>
              <div class="text-right ml-20">
                <div class="text-red-500 font-bold text-xs">Balance Due: $190.00</div>
                <div class="text-gray-700 text-xs">(Next Billing Date: August 1st, 2024)</div>
              </div>
            </div>
          </div>
 
        </div>
      </div>

      <!-- Right Pane -->
      <div class="w-full lg:w-3/5 flex flex-col">
        <!-- Billing History -->
        <div class="rounded-lg mb-2 p-2 border border-gray-300 shadow-md">
          <div class="flex flex-col md:flex-row mb-2">
            <div class="lg:w-2/3 pr-4">
              <div class="flex items-center mb-2">
                <div class="flex rounded-full border border-tvBlue px-2">
                <CreditCardIcon class="w-6 h-6 text-tvBlue mr-1" />
                <div class="inline-block font-bold text-xs text-gray-00 mt-1 whitespace-nowrap">BILLING HISTORY</div>
                </div>
                <div class="inline-block ml-2 text-xs italic text-tvOrange font-bold">View or download past invoices & statements.</div>
              </div>
              <div v-for="(invoice, index) in invoices" :key="index" class="flex items-center mt-2 pl-3 pt-1">
                <div class="flex w-full text-xs">
                  <div class="w-1/3">{{ invoice.type_name }} #{{ invoice.id }}</div>
                  <div class="w-1/3">{{ invoice.date }}</div>
                  <div class="w-1/3 flex items-center">
                  <div class="w-5 h-4 mr-2" :style="{ backgroundImage: `url(${pdf_icon})`, backgroundSize: 'cover' }"></div>
                  <a :href="`https://portal.talkvox.com/includes/php/download_invoices.php?id=${invoice.id}&FileName=${invoice.fileName}`" class="text-blue-600 whitespace-nowrap">Download</a>
                </div>

                </div>
              </div>
            </div>
            <div class="w-1/3 pl-4 hidden md:block mr-1">
              <div class="relative w-full h-auto bg-gray-100 rounded-lg pt-1 pr-5">
                <div class="absolute top-0 left-0 w-full h-full bg-gray-100 bg-opacity-10 rounded-lg border shadow border-gray-300"></div>
                <div class="relative p-2">
                  <div class="text-center font-bold text-tvOrange mb-1 text-xs">Credit Card On File</div>
                  <div class="flex mb-1 text-xs">
                    <div class="w-1/2 text-right pr-1 font-bold">Cardholder Name:</div>
                    <div class="w-1/2 text-left">{{ paymentMethod.cardholderName }}</div>
                  </div>
                  <div class="flex mb-1 text-xs">
                    <div class="w-1/2 text-right pr-1 font-bold">Billing Address:</div>
                    <div class="w-1/2 text-left">{{ paymentMethod.billingAddress }}</div>
                  </div>
                  <div class="flex mb-1 text-xs">
                    <div class="w-1/2 text-right pr-1 font-bold"></div>
                    <div class="w-1/2 text-left">{{ paymentMethod.billingCityStateZip }}</div>
                  </div>
                  <div class="flex mb-1 text-xs">
                    <div class="w-1/2 text-right pr-1 font-bold">Card Number:</div>
                    <div class="w-1/2 text-left">xxxx-xxxx-xxxx-{{ paymentMethod.last4 }}</div>
                  </div>
                  <div class="flex mb-1 text-xs">
                    <div class="w-1/2 text-right pr-1 font-bold">Exp Date:</div>
                    <div class="w-1/2 text-left">{{ paymentMethod.expDate }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- Support Center -->
        <div class="rounded-lg mb-2 border border-gray-300 shadow-md">
          <div class="p-2">
            <div class="flex items-center mb-2">
              <div class="flex rounded-full border border-tvBlue px-2">
              <LifebuoyIcon class="w-6 h-6 text-tvBlue mr-1" />
              <div class="inline-block font-bold text-xs text-gray-600 mt-1">SUPPORT CHANNEL</div>
              </div>
              <div class="inline-block ml-2 text-xs italic text-tvOrange font-bold">How can we help you today?</div>
            </div>
            <div class="flex justify-between">
              <div class="relative w-1/5 h-20 m-1 bg-gray-100 rounded-lg flex flex-col items-center justify-center cursor-pointer text-xs border">
                <ShoppingCartIcon class="w-10 h-10 text-tvBlue mr-3 ml-1 " />
                <div class="text-center font-bold">Order Hardware</div>
              </div>
              <div class="relative w-1/5 h-20 m-1 bg-gray-100 rounded-lg flex flex-col items-center justify-center cursor-pointer text-xs border">
                <ArrowPathRoundedSquareIcon class="w-10 h-10 text-tvBlue mr-3 ml-1 " />
                <div class="text-center font-bold">Manage Services</div>
              </div>
              <div class="relative w-1/5 h-20 m-1 bg-gray-100 rounded-lg flex flex-col items-center justify-center cursor-pointer text-xs border">
                <HashtagIcon class="w-9 h-9 text-tvBlue mr-3 ml-1 " />
                <div class="text-center font-bold">Add Phone Numbers</div>
              </div>
              <div class="relative w-1/5 h-20 m-1 bg-gray-100 rounded-lg flex flex-col items-center justify-center cursor-pointer text-xs border">
                <QuestionMarkCircleIcon class="w-10 h-10 text-tvBlue mr-3 ml-1 " />
                <div class="text-center font-bold">FAQ's</div>
              </div>
              <div class="relative w-1/5 h-20 m-1 bg-gray-100 rounded-lg flex flex-col items-center justify-center cursor-pointer text-xs border">
                <ChatBubbleLeftRightIcon class="w-10 h-10 text-tvBlue mr-3 ml-1 " />
                <div class="text-center font-bold">Contact Us</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Recent Calls -->
        
        <div class="rounded-lg p-2 flex-grow border border-gray-300 shadow-md pb-2">
          <div class="flex items-center mb-2">
            <div class="flex rounded-full border border-tvBlue px-2 py-1">
            <PhoneIcon class="w-4 h-4 text-tvBlue mr-1" />
            <div class="inline-block font-bold text-xs text-gray-600">CALL ACTIVITY</div>
            </div>
            <div class="inline-block ml-2 text-xs italic text-tvOrange font-bold">Your most recent phone calls at a glance.</div>
          </div>
          <div class="overflow-y-auto max-h-60">
       
              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" class="py-1 pl-12 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-7">Date</th>
                    <th scope="col" class="px-1 py-1 text-left text-xs font-semibold text-gray-900">Call Type</th>
                    <th scope="col" class="px-1 py-1 text-left text-xs font-semibold text-gray-900">Caller ID</th>
                    <th scope="col" class="px-1 py-1 text-left text-xs font-semibold text-gray-900">Destination</th>
                    <th scope="col" class="px-1 py-1 text-left text-xs font-semibold text-gray-900">Call Duration</th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                  <tr v-for="(call, index) in calls" :key="index">
                    <td class="whitespace-nowrap py-3 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-0">
                      <PhoneArrowUpRightIcon class="w-4 h-4 text-tvOrange inline-block mr-2" />
                      {{ call.date }}
                    </td>
                    <td class="whitespace-nowrap px-1 py-3 text-xs text-gray-500">{{ call.callType }}</td>
                    <td class="whitespace-nowrap px-1 py-3 text-xs text-gray-500">{{ call.callerID }}</td>
                    <td class="whitespace-nowrap px-1 py-3 text-xs text-gray-500">{{ call.destination }}</td>
                    <td class="whitespace-nowrap px-1 py-3 text-xs text-gray-500">{{ call.duration }} Seconds</td>
                  </tr>
                </tbody>
              </table>


          </div>
        </div>


      </div>
    </div>
  </div>
</template>


<script>
import pdf_icon from '@/assets/pdf_icon.png';
import SwirlImage from '@/assets/swirl2.jpg';
import { ArrowPathRoundedSquareIcon, ChatBubbleLeftRightIcon, CreditCardIcon, HashtagIcon, LifebuoyIcon, PhoneArrowUpRightIcon, PhoneIcon, QuestionMarkCircleIcon, ShoppingCartIcon } from '@heroicons/vue/24/outline';


export default {
  data() {
    return {
      backgroundStyle: {
        backgroundImage: `url(${SwirlImage})`,
        //backgroundSize: 'cover', 
        backgroundSize: '200% auto',     // Optional: to cover the entire div
        backgroundPosition: 'center', // Optional: to center the image
        backgroundRepeat: 'no-repeat' // Optional: to avoid repeating the image
      },
      pdf_icon: pdf_icon,
      invoices: [
        { id: 1563453, type_name: 'Invoice', date: '01/01/2024', fileName: 'Invoice_1563453.pdf' },
        { id: 1567547, type_name: 'Invoice', date: '02/01/2024', fileName: 'Invoice_1567547.pdf' },
        { id: 1567677, type_name: 'Invoice', date: '03/01/2024', fileName: 'Invoice_1567677.pdf' },
        { id: 1765757, type_name: 'Invoice', date: '04/01/2024', fileName: 'Invoice_1765757.pdf' },
        // Add more invoices here
      ],
      paymentMethod: {
        cardholderName: 'John Doe',
        billingAddress: '1234 Street Name',
        billingCityStateZip: 'City, State, ZIP',
        last4: '1234',
        expDate: '12/24',
      },
      calls: [
      { date: '01/01/2024', callType: 'inbound', callerID: '1234567890', destination: '0987654321', duration: '60' },
      { date: '01/01/2024', callType: 'inbound', callerID: '1234567890', destination: '0987654321', duration: '60' },
      { date: '01/01/2024', callType: 'inbound', callerID: '1234567890', destination: '0987654321', duration: '60' },
      { date: '01/01/2024', callType: 'inbound', callerID: '1234567890', destination: '0987654321', duration: '60' },
      { date: '01/01/2024', callType: 'inbound', callerID: '1234567890', destination: '0987654321', duration: '60' },        
        // Add more calls here
      ],
    };
  },
  components: {
    CreditCardIcon,
    LifebuoyIcon,
    PhoneIcon,
    PhoneArrowUpRightIcon,
    ShoppingCartIcon,
    ArrowPathRoundedSquareIcon,
    HashtagIcon,
    QuestionMarkCircleIcon,
    ChatBubbleLeftRightIcon,
  },
};
</script>

<style scoped>
/* Add any scoped styles here if needed */
</style>
