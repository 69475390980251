<template>
  <div class="flex flex-col items-center">

    <div class="flex flex-col items-center mt-4">
      <img class="h-32" alt="logo" src="/assets/logo.png">
      <h1 class="text-4xl mt-4">Welcome</h1>
      <h4 class="text-lg mt-2">Please enter your information to sign up</h4>
    </div>

    <form action="" name="register-form" class="w-full max-w-md mt-4">
      <div class="mb-4">
        <label for="fullName" class="block text-sm font-bold">Full Name</label>
        <input :disabled="isRegistering" placeholder="John Doe" type="text" id="fullName" v-model="account.fullName" @blur="validateFullName" :class="fullNameError ? 'border-red-500' : ''" class="mt-1 p-2 w-full border rounded-md"/>
        <p v-if="fullNameError" class="text-red-500 text-xs">{{ fullNameError }}</p>
      </div>

      <div class="mb-4">
        <label for="email" class="block text-sm font-bold">Email</label>
        <input :disabled="isRegistering" placeholder="john.doe@example.com" type="email" id="email" v-model="account.email" @blur="validateEmail" :class="emailError ? 'border-red-500' : ''" class="mt-1 p-2 w-full border rounded-md"/>
        <p v-if="emailError" class="text-red-500 text-xs">{{ emailError }}</p>
      </div>

      <div class="mb-4">
        <label for="phone" class="block text-sm font-bold">Phone</label>
        <input :disabled="isRegistering" placeholder="000-000-0000" type="text" id="phone" v-model="account.phone" @blur="validatePhone" :class="phoneError ? 'border-red-500' : ''" class="mt-1 p-2 w-full border rounded-md"/>
        <p v-if="phoneError" class="text-red-500 text-xs">{{ phoneError }}</p>
      </div>

      <div class="mb-4">
        <label for="address" class="block text-sm font-bold">Address</label>
        <input :disabled="isRegistering" placeholder="100 Main St. Anywhere, AZ 85001" type="text" id="address" v-model="account.address" @blur="validateAddress" :class="addressError ? 'border-red-500' : ''" class="mt-1 p-2 w-full border rounded-md"/>
        <p v-if="addressError" class="text-red-500 text-xs">{{ addressError }}</p>
      </div>

      <p v-if="apiError" class="text-red-500 text-xs">{{ apiError }}</p>
      <input type="button" value="Register" @click="performRegister" :disabled="isRegistering" class="mt-4 px-4 py-2 bg-tvOrange text-white rounded hover:bg-red-800" />
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Register',
  methods: {
    ...mapActions(['register']),
    validateFullName() {
      if (!this.account.fullName || this.account.fullName.split(' ').length < 2) {
        this.fullNameError = 'Full name must contain at least a first and last name';
      } else {
        this.fullNameError = '';
      }
    },
    validateEmail() {
      const re = /\S+@\S+\.\S+/;
      if (!re.test(this.account.email)) {
        this.emailError = 'Invalid email address';
      } else {
        this.emailError = '';
      }
    },
    validatePhone() {
      const re = /^(\d{3}-\d{3}-\d{4}|\d{10})$/;
      if (!re.test(this.account.phone)) {
        this.phoneError = 'Invalid phone format. Use 000-000-0000';
      } else {
        this.phoneError = '';
      }
    },
    validateAddress() {
      if (this.account.address.length < 10) {
        this.addressError = 'Address must be at least 10 characters long';
      } else {
        this.addressError = '';
      }
    },
    async performRegister() {
      this.apiError = '';
      this.validateFullName();
      this.validateEmail();
      this.validatePhone();
      this.validateAddress();
      if (this.fullNameError || this.emailError || this.phoneError || this.addressError) {
        return;
      }
      try {
        this.isRegistering = true;
        const success = await this.register(this.account);
        if (success) {
          this.$router.push({ path: '/login', query: { message: "Registration successful, an email with a link to the dashboard has been sent." } });
        }
      } catch (error) {
        this.apiError = 'Unknown error occurred, please try again later.';
      } finally {
        this.isRegistering = false;
      }
    },
  },
  data() {
    return {
      account: {
        fullName: '',
        email: '',
        phone: '',
        address: ''
      },
      fullNameError: '',
      emailError: '',
      phoneError: '',
      addressError: '',
      apiError: '',
      isRegistering: false
    };
  }
};
</script>
