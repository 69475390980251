<template>
  <div class="backimg"></div>
  <div class="content-container">
    <LoadingOverlay :show="isLoading" /> <!-- Display the loading overlay -->

    <!-- Toast Message -->
    <transition name="v">
      <div v-if="showMessage && message" class="fixed top-0 right-0 m-4 p-4 bg-tvBlue text-white rounded">
        {{ message }}
        <a href="#" class="ml-4 text-white underline" @click.prevent="closeMessage">Close</a>
      </div>
    </transition>

    <div class="flex flex-col">
      <div class="flex flex-col items-center mt-4">
        <img alt="logo" class="h-32" src="/assets/logo.png">
        <h4 class="text-lg mt-2">Please enter your credentials to log in</h4>
      </div>

      <!-- Standard Login Form -->
      <form @submit.prevent="performLogin" name="login-form" class="w-full max-w-sm mt-4">
        <div class="mb-4">
          <label for="email" class="block text-sm font-bold text-gray-700">Email</label>
          <input
            :disabled="isLoggingIn"
            type="email"
            id="email"
            v-model="account.email"
            @blur="validateEmail"
            :class="emailError ? 'border-blue-500' : ''"
            class="mt-1 p-3 w-full rounded-lg border border-gray-300 bg-gray-100 focus:bg-white focus:border-gray-400 shadow-sm"
            @keyup.enter="performLogin"
            autocomplete="username"
          />
          <p v-if="emailError" class="txt-custom-color text-xs">{{ emailError }}</p>
        </div>

        <div class="mb-4 text-left">
          <label for="password" class="block text-sm font-bold text-gray-700">Password</label>
          <input
            :disabled="isLoggingIn"
            type="password"
            id="password"
            v-model="account.password"
            @blur="validatePassword"
            :class="passwordError ? 'border-blue-500' : ''"
            class="mt-1 p-3 w-full rounded-lg border border-gray-300 bg-gray-100 focus:bg-white focus:border-gray-400 shadow-sm"
            @keyup.enter="performLogin"
            autocomplete="current-password"
          />
          <p v-if="passwordError" class="txt-custom-color text-xs">{{ passwordError }}</p>
          <a href="/forgot" class="self-end text-gray-500 text-sm self-end">Forgot Password?</a>
        </div>

        <p v-if="apiError" class="txt-custom-color text-xs">{{ apiError }}</p>
        <button :disabled="isLoggingIn" class="mt-4 w-full px-4 py-2 bg-tvOrange text-white rounded-lg hover:bg-orange-600 shadow-md">Login</button>
      </form>

      <span class="ml-2 text-sm text-gray-400 mt-1">Don't have an account?
        <a href="/contactus" class="self-end text-tvBlue text-sm font-bold">Sign Up</a>
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import LoadingOverlay from '@/views/LoadingOverlay.vue'; // Import LoadingOverlay

export default {
  name: 'CombinedLogin',
  components: {
    LoadingOverlay, // Register LoadingOverlay component
  },
  computed: {
    ...mapState(['isLoading']), // Map isLoading from Vuex state
    message() {
      return this.$route.query.message;
    }
  },
  methods: {
    closeMessage() {
      this.showMessage = false;
    },
    ...mapActions(['login']),
    validateEmail() {
      const re = /\S+@\S+\.\S+/;
      if (!re.test(this.account.email)) {
        this.emailError = 'Invalid email address';
      } else {
        this.emailError = '';
      }
    },
    validatePassword() {
      if (this.account.password.length < 8) {
        this.passwordError = 'Password must be at least 8 characters long';
      } else {
        this.passwordError = '';
      }
    },
    async performLogin() {
      this.apiError = '';
      this.validateEmail();
      this.validatePassword();

      if (this.emailError || this.passwordError) return;

      try {
        this.isLoggingIn = true;
        this.$store.commit('setLoading', true); // Start loading
        const response = await this.login(this.account);

        if (response && response.data && response.data.success && response.data.success.token) {
          localStorage.setItem('token', response.data.success.token);
          window.location.href = `${window.location.origin}/`;
        } else {
          this.apiError = 'Login failed, please try again.';
        }
      } catch (error) {
        console.error('Error during login:', error);
        this.apiError = 'Unknown error occurred, please try again later.';
      } finally {
        this.isLoggingIn = false;
        this.$store.commit('setLoading', false); // Stop loading
      }
    }
  },
  data() {
    return {
      account: {
        email: '',
        password: ''
      },
      emailError: '',
      passwordError: '',
      apiError: '',
      isLoggingIn: false,
      showMessage: true,
      messageTimeout: null,
    };
  },
  created() {
    const token = localStorage.getItem('token');
    if (token) {
      this.$router.push('/');
    }
    if (this.message) {
      this.messageTimeout = setTimeout(() => {
        this.showMessage = false;
      }, 3500);
    }
  },
  beforeDestroy() {
    clearTimeout(this.messageTimeout);
  }
};
</script>


<style scoped>
.v-enter-active, .v-leave-active {
  transition: opacity 3s;
}
.v-enter, .v-leave-to {
  opacity: 0;
}

.txt-custom-color {
  color: #f47e40; /* Set text color to ensure visibility */
}

.backimg {
  opacity: 0.30;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background: url('~@/assets/swirl_crop.jpg') no-repeat center center;
  background-color: #FFF;
  background-size: cover;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  z-index: 1;
}
</style>

