import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import ContactUsSuccess from '../views/ContactUsSuccess.vue';
import RestorePassword from '../views/SetPassword.vue';
import ContactUs from "@/views/ContactUs.vue";
import Dashboard from '../views/Dashboard.vue';
import status from '../views/status.vue';
import Profile from '../views/Profile.vue';
import Users from '../views/Users.vue';
import Calendar from '../views/Calendar.vue';
import AgentDashboard from '../views/AgentDashboard.vue';
import AdminDashboard from '../views/AdminDashboard.vue';

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/agent-dashboard',
    name: 'AgentDashboard',
    component: AgentDashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin-dashboard',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: { requiresAuth: false }
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: ContactUs,
    meta: { requiresAuth: false }
  },
  {
    path: '/contactUs-success',
    name: 'contactUsSuccess',
    component: ContactUsSuccess,
    meta: { requiresAuth: false }
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: ForgotPassword,
    meta: { requiresAuth: false }
  },
  {
    path:'/password-reset',
    name: 'setPassword',
    component: RestorePassword,
    meta: { requiresAuth: false }
  },
  {
    path: '/status',
    name: 'status',
    component: status,
    meta: { requiresAuth: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    path: '/Users',
    name: 'Users',
    component: Users,
    meta: { requiresAuth: true }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  const loggedIn = localStorage.getItem('token');
  if (to.meta.requiresAuth && !loggedIn) {
    return next({ path: '/login' });
  }

  if (to.path === '/' && loggedIn) {
    // Assuming the Vuex store is already initialized
    const store = await import('../store'); // Lazy load the store to avoid circular dependency issues
    await store.default.dispatch('roles/fetchRoles');

    if (store.default.getters['roles/hasRole']('Sales Agent')) {
      return next({ name: 'AgentDashboard' });
    }
    if (store.default.getters['roles/hasRole']('PBX Admin')) {
      return next({ name: 'AdminDashboard' });
    }
  }
  next();
});

export default router;
