<template>
  <div class="flex flex-col items-center max-w-sm mx-auto sm:pt-10 justify-center min-h-screen">
    <!-- Loading Overlay Component -->
    <LoadingOverlay :show="isLoading" />

    <div class="flex flex-col items-center">
      <div class="flex flex-col w-full">
        <img class="h-20 object-contain mb-4 mx-auto" src="/assets/logo.png" alt="logo" />
        <h1 class="text-2xl mb-2 text-center font-semibold">Set Your Password</h1>
        <p class="text-center text-gray-600 mb-4">
          Please enter your new password below. Make sure your password is at least 8 characters long and contains a mix of letters, numbers, and symbols.
        </p>
        <form @submit.prevent="submit" class="space-y-4">
          <div>
            <label for="password" class="block text-sm font-bold text-gray-700">New Password</label>
            <input id="password" type="password" v-model="account.password" @blur="validatePassword" :class="passwordError ? 'border-red-500' : ''" class="w-full rounded-lg border border-gray-300 bg-gray-100 focus:bg-white focus:border-gray-400 shadow-sm p-3" />
            <div v-if="passwordError" class="text-red-500 mt-1">{{ passwordError }}</div>
          </div>
          <div>
            <label for="repeatPassword" class="block text-sm font-bold text-gray-700">Confirm New Password</label>
            <input id="repeatPassword" type="password" v-model="account.password_confirmation" @blur="validateRepeatPassword" :class="repeatPasswordError ? 'border-red-500' : ''" class="w-full rounded-lg border border-gray-300 bg-gray-100 focus:bg-white focus:border-gray-400 shadow-sm p-3" />
            <div v-if="repeatPasswordError" class="text-red-500 mt-1">{{ repeatPasswordError }}</div>
          </div>
          <button
            type="submit"
            :class="isSubmitting || isLoading ? 'bg-tvOrange' : 'bg-tvOrange'"
            class="w-full text-white rounded-lg px-4 py-2 flex justify-center items-center shadow-md hover:bg-orange-600"
            :disabled="isSubmitting || isLoading"
          >
            <span v-if="!isLoading">Submit</span>
            <span v-else>
              <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
              </svg>
              Processing...
            </span>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AccountService from '@/services/AccountService';
import { useRoute, useRouter } from 'vue-router';
import { mapState, mapMutations } from 'vuex';
import LoadingOverlay from '@/views/LoadingOverlay.vue'; // Import LoadingOverlay

export default {
  name: 'SetPassword',
  components: {
    LoadingOverlay, // Register LoadingOverlay component
  },
  data() {
    return {
      account: {
        password: '',
        password_confirmation: '',
        token: '',
        email: ''
      },
      passwordError: '',
      repeatPasswordError: '',
      isSubmitting: false
    };
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    return { route, router };
  },
  computed: {
    ...mapState(['isLoading']), // Map the isLoading state from Vuex
  },
  created() {
    this.account.token = this.route.query.token || '';
    if (Array.isArray(this.route.query.email)) {
      this.account.email = this.route.query.email[0];
    } else {
      this.account.email = this.route.query.email || this.$route.params.email || '';
    }
  },
  methods: {
    ...mapMutations(['setLoading']), // Map the setLoading mutation from Vuex
    validatePassword() {
      this.passwordError = this.account.password.length < 8 ? 'Password must be at least 8 characters long' : '';
    },
    validateRepeatPassword() {
      this.repeatPasswordError = this.account.password !== this.account.password_confirmation ? 'Passwords do not match' : '';
    },
    async submit() {
      this.isSubmitting = true;
      this.validatePassword();
      this.validateRepeatPassword();
      if (!this.passwordError && !this.repeatPasswordError) {
        try {
          this.setLoading(true); // Start loading
          const payload = {
            password: this.account.password,
            password_confirmation: this.account.password_confirmation,
            token: this.account.token,
            email: this.account.email
          };
          console.log(payload); // Log payload for debugging
          const status = await AccountService.resetPassword(payload);
          this.$router.push({ path: '/login', query: { message: 'Your password has been set!' } });
        } catch (error) {
          alert(error.message);
        } finally {
          this.setLoading(false); // Stop loading
          this.isSubmitting = false;
        }
      } else {
        this.isSubmitting = false;
      }
    }
  }
};
</script>

<style scoped>
/* Add any additional styles here */
</style>
