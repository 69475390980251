<template>
  <Disclosure as="nav" class="bg-white border-b border-gray-300 shadow-md">
    <LoadingOverlay :show="isLoading" /> <!-- Add the LoadingOverlay component here -->
    <div class="mx-auto w-full px-2 sm:px-4 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex px-2 lg:px-0">
          <div class="flex-shrink-0 flex items-center">
            <img class="logo block lg:hidden" :src="require('/public/assets/logo.png')" alt="Your Company">
            <img class="logo hidden lg:block" :src="require('/public/assets/logo.png')" alt="Your Company">
          </div>
          <div class="hidden lg:ml-6 lg:flex lg:space-x-8">
            <!-- Dashboard Menu Item -->
            <Menu as="div" class="relative mt-5">
              <MenuButton class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 focus:outline-none transition duration-150 ease-in-out border-yellow-500 text-gray-900" @click="navigateToDashboard">
                DASHBOARD
              </MenuButton>
            </Menu>
            <!-- Other Dynamic Menu Items -->
            <Menu as="div" class="relative mt-5" v-for="(item, index) in filteredMenuItems" :key="index" @click="navigateTo(item.text)">
              <MenuButton class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 focus:outline-none transition duration-150 ease-in-out" :class="item.current ? 'border-yellow-500 text-gray-900' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'">
                {{ item.text }}
                <ChevronDownIcon class="ml-2 h-5 w-5" aria-hidden="true" v-if="item.submenu && item.submenu.length > 0" />
              </MenuButton>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="origin-top-right absolute z-10 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div v-for="submenuItem in item.submenu" :key="submenuItem" @click="navigateTo(submenuItem)">
                    <MenuItem>
                      <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">{{ submenuItem }}</a>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>

        <div class="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
          <div class="max-w-lg w-full lg:max-w-xs">
            <label for="search" class="sr-only">Search</label>
            <div class="relative">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input id="search" name="search" class="block w-full pl-10 pr-3 py-2 border border-transparent leading-5 bg-gray-50 text-gray-900 placeholder-gray-500 focus:outline-none focus:bg-white focus:border-white focus:ring-white sm:text-sm" placeholder="Search" type="search">
            </div>
          </div>
        </div>

        <div class="flex lg:hidden">
          <DisclosureButton class="p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>

        <div class="hidden lg:flex lg:items-center z-10">
          <button class="flex-shrink-0 p-1 border-2 border-transparent rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100" type="button">
            <BellIcon class="h-6 w-6" aria-hidden="true" />
          </button>
          <Menu as="div" class="ml-4 relative flex-shrink-0">
            <div>
              <MenuButton class="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <div class="avatar-container">
                  <img class="h-8 w-8 rounded-full" :src="require('@/assets/avatar.png')" alt="User Avatar" />
                </div>  
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem>
                  <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" @click="navigateTo('Profile')">Your Profile</a>
                </MenuItem>
                <MenuItem>
                  <button @click="signOut" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">Sign out</button>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <DisclosurePanel class="lg:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <a href="#" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Dashboard</a>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore, mapState } from 'vuex'; // Import mapState from Vuex
import AccountService from '@/services/AccountService'; // Import the updated AccountService
import LoadingOverlay from '@/views/LoadingOverlay.vue'; // Import the LoadingOverlay component
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon, MagnifyingGlassIcon, Bars3Icon, BellIcon } from '@heroicons/vue/20/solid';

const router = useRouter();
const store = useStore();

// Map the isLoading state from Vuex to the component
const isLoading = computed(() => store.state.isLoading);

const navigateTo = (menuItem) => {
  if (menuItem === 'Client Status') {
    router.push({ name: 'status' });
  } else if (menuItem === 'DASHBOARD') {
    navigateToDashboard();
  } else if (menuItem === 'Profile') {
    router.push({ name: 'profile' });
  } else if (menuItem === 'User Management') {
    router.push({ name: 'Users' });
  } else if (menuItem === 'Calendar') {
    router.push({ name: 'Calendar' });
  }
};

const menuItems = ref([
  {
    text: 'DASHBOARD',
    current: true,
    submenu: []
  },
  {
    text: 'COMMUNICATIONS',
    current: false,
    submenu: []
  },
  {
    text: 'PBX',
    current: false,
    submenu: ['User Admin', 'Phone Lines', 'Numbers', 'Call Groups', 'Extensions', 'Call Forward', 'Schedule', 'Voice Mail', 'Auto Attendand']
  },
  {
    text: 'CRM',
    current: false,
    submenu: []
  },
  {
    text: 'MONITORING',
    current: false,
    submenu: ['Call Detail Records', 'Call Graphs', 'Listen & Barge']
  },
]);

const signOut = () => {
  localStorage.removeItem('token');
  router.push({ name: 'login' });
  window.location.reload();
};

const fetchRoles = async () => {
  try {
    const response = await AccountService.fetchRoles(); // Use AccountService to fetch roles
    store.commit('roles/setRoles', response.data);
  } catch (error) {
    console.error('Failed to fetch roles:', error);
  }
};

const navigateToDashboard = () => {
  if (store.getters['roles/hasRole']('Sales Agent')) {
    router.push({ name: 'AgentDashboard' });
  } else if (store.getters['roles/hasRole']('PBX Admin')) {
    router.push({ name: 'AdminDashboard' });
  } else {
    router.push({ name: 'Dashboard' }); // Default dashboard route
  }
};

const filteredMenuItems = computed(() => {
  return menuItems.value.filter(item => {
    if (item.text === 'PBX' && store.getters['roles/hasRole']('PBX Admin')) return true;
    if (item.text === 'COMMUNICATIONS' && store.getters['roles/hasRole']('Communications')) return true;
    if (item.text === 'CRM' && store.getters['roles/hasRole']('Sales Agent')) return true;
    if (item.text === 'MONITORING' && store.getters['roles/hasRole']('PBX Admin')) return true;
    return false;
  });
});

onMounted(() => {
  fetchRoles();
});

</script>

<style scoped>
.logo {
  width: 160px;
}
.avatar-container {
  background-color: white;
  border-radius: 50%;
}
</style>
