<template>
  <div 
    class="fixed bottom-0 right-20 w-72 bg-gray-700 rounded-t-lg shadow-lg overflow-hidden transition-all duration-300 ease-in-out"
    :style="{ height: expanded ? `${contentHeight + 40}px` : '2.5rem', backgroundImage: `url(${PhoneBack})`, backgroundSize: 'cover' }"
    ref="phoneContainer"
  >
    <div :class="'text-white text-center py-2 font-bold flex justify-between items-center px-4 '">
      Kris Drebin
      <button @click="toggleExpand">
        <svg v-if="!expanded" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>
    </div>
    <div ref="content" class="p-4 flex flex-col items-center">
      <div class="flex items-center justify-center h-full flex-col">
        <!-- Connecting the call message -->
        <div v-if="connectingCall" class="text-center">
          <p class="text-xl text-white mt-36">Connecting the call..</p>
        </div>
        
        <!-- Call in Progress -->
        <div v-else-if="callActive" class="text-center">
          <p class="text-white text-sm">{{ formatDuration(callDuration) }}</p> <!-- Call Timer -->
          <p class="text-2xl text-white mt-2">{{ formattedCallerId }}</p> <!-- Caller ID -->
        </div>
        
        <!-- Other labels like Incoming Call and Caller ID -->
        <div v-else class="mb-4 text-center">
          <p v-if="incomingCall" class="text-xl text-white">Incoming Call</p>
          <p v-if="callerName" class="font-bold text-white text-lg">{{ callerName }}</p>
          <p class="text-2xl text-white">{{ formattedCallerId }}</p>
          <p v-if="callActive && callDuration" class="text-sm">{{ formatDuration(callDuration) }}</p>
        </div>
      </div>
      <input
        v-if="!incomingCall && !callActive && !connectingCall"
        type="tel"
        v-model="phoneNumber"
        placeholder="Enter phone number"
        class="p-3 w-full mb-4 mx-10 rounded-lg bg-white bg-opacity-20 text-white placeholder-gray-300 border-none"
      />
      <div v-if="!incomingCall && !callActive && !connectingCall" class="grid grid-cols-3 gap-4">
        <button
          v-for="n in 9"
          :key="n"
          @click="appendToPhoneNumber(n)"
          class="w-16 h-16 bg-white bg-opacity-20 text-white rounded-full text-center leading-[40px] text-2xl"
        >
          {{ n }}
        </button>
      </div>
      <div v-if="!incomingCall && !callActive && !connectingCall" class="grid grid-cols-3 gap-4 mt-2">
        <button class="w-16 h-16 bg-white bg-opacity-20 text-white rounded-full text-center leading-[40px] text-2xl">
          *
        </button>
        <button @click="appendToPhoneNumber(0)" class="w-16 h-16 bg-white bg-opacity-20 text-white rounded-full text-center leading-[40px] text-2xl">
          0
        </button>
        <button class="w-16 h-16 bg-white bg-opacity-20 text-white rounded-full text-center leading-[40px] text-2xl">
          #
        </button>
      </div>

      <!-- New circular call button added below the dialpad -->
      <div v-if="!incomingCall && !callActive && !connectingCall" class="flex justify-center mt-4">
        <button @click="makeCall" class="w-16 h-16 bg-tvOrange text-white rounded-full flex items-center justify-center">
          Call
        </button>
      </div>

      <!-- Move the answer button to the bottom -->
      <div v-if="incomingCall && !callActive && !connectingCall" class="flex justify-center mt-auto w-full absolute bottom-0 p-4">
        <button @click="answerCall" class="w-full py-2 bg-tvOrange text-white rounded-[13px]">
          Answer
        </button>
      </div>

      <!-- End Call Button -->
      <div v-if="callActive && !connectingCall" class="flex justify-center mt-auto w-full absolute bottom-0 p-4">
        <button @click="hangupCall" class="w-full py-3 bg-red-600 text-white rounded-[13px] text-xl">
          End Call
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import * as SIP from 'sip.js';
import PhoneBack from '@/assets/soft_phone_back.png';

export default {
  data() {
    return {
      expanded: false,
      contentHeight: 0,
      phoneNumber: "",
      session: null,
      userAgent: null,
      registerer: null,
      incomingCall: false,
      callAnswered: false,
      callActive: false,
      callDuration: null,
      callStartTime: null,
      callTimeout: null,
      showKeypad: false,
      connectingCall: false, // New state to track connecting call
      callerId: null,
      callerName: null,
      formattedCallerId: "",
      tvBlue: 'bg-tvBlue',  // Custom Tailwind color class
      PhoneBack: PhoneBack,  
    };
  },
  mounted() {
    this.calculateContentHeight();
    this.initSIP().then(() => {
      this.registerer = new SIP.Registerer(this.userAgent);
      this.registerer.register();
    }).catch(err => {
      console.error('Failed to initialize SIP', err);
    });
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
      if (this.expanded) {
        this.$refs.phoneContainer.style.height = `${this.contentHeight + 40}px`;
      } else {
        this.$refs.phoneContainer.style.height = '3rem';
      }
    },
    calculateContentHeight() {
      this.contentHeight = this.$refs.content.scrollHeight;
    },
    appendToPhoneNumber(number) {
      this.phoneNumber += number;
    },
    async initSIP() {
      const transportOptions = {
        server: "wss://belize.talkvox.com:8089/ws",
      };
      
      const uri = SIP.UserAgent.makeURI("sip:TV50654411@belize.talkvox.com");

      const userAgentOptions = {
        uri,
        transportOptions,
        authorizationUsername: "TV50654411",
        authorizationPassword: "yji867",
        displayName: "Kris Drebin",
      };

      this.userAgent = new SIP.UserAgent(userAgentOptions);

      this.userAgent.delegate = {
        onInvite: (invitation) => {
          console.log('Received an INVITE');
          this.session = invitation;
          this.incomingCall = true;
          this.callerName = invitation.remoteIdentity.displayName || "";
          this.callerId = this.parseCallerId(invitation.remoteIdentity.uri.toString());
          this.formattedCallerId = this.formatPhoneNumber(this.callerId);
          this.expandOnIncomingCall();
          this.startCallTimeout();

          this.session.stateChange.addListener((newState) => {
            if (newState === SIP.SessionState.Established) {
              this.callActive = true;
              this.connectingCall = false; // Call is connected
              this.handleMedia();
            } else if (newState === SIP.SessionState.Terminated || newState === SIP.SessionState.Terminating) {
              this.connectingCall = false; // Reset if the call is terminated
            }
          });
        },
      };

      try {
        await this.userAgent.start();
        console.log("Successfully connected to Asterisk.");
      } catch (err) {
        console.error('Failed to start UserAgent', err);
      }
    },
    expandOnIncomingCall() {
      if (!this.expanded) {
        this.expanded = true;
        this.$refs.phoneContainer.style.height = `${this.contentHeight + 40}px`;
      }
    },
    handleMedia() {
      const sessionDescriptionHandler = this.session?.sessionDescriptionHandler;
      if (sessionDescriptionHandler && sessionDescriptionHandler.remoteMediaStream) {
        const remoteStream = sessionDescriptionHandler.remoteMediaStream;
        const remoteAudio = new window.Audio();
        remoteAudio.srcObject = remoteStream;
        remoteAudio.autoplay = true;
        console.log("Audio should be playing from the remote stream.");
      } else {
        console.error("No remote media stream available.");
      }

      // Listen for session state changes to detect hangup from the other side
      this.session.stateChange.addListener((newState) => {
        if (newState === SIP.SessionState.Terminated) {
          console.log("Call terminated by the other side.");
          this.resetCallState();
        }
      });

      this.startCallTimer();  // Start the call duration timer
    },
    makeCall() {
      if (this.userAgent && this.phoneNumber) {
        const target = SIP.UserAgent.makeURI(`sip:${this.phoneNumber}@belize.talkvox.com`);
        
        // Set the formattedCallerId to the dialed number
        this.formattedCallerId = this.formatPhoneNumber(this.phoneNumber);
        
        // Start showing the call progress screen and the timer
        this.callActive = true;
        this.startCallTimer(); // Start the call duration timer

        this.session = new SIP.Inviter(this.userAgent, target);
        
        // Delay the media handling until the call is established
        this.session.stateChange.addListener((newState) => {
          if (newState === SIP.SessionState.Established) {
            this.handleMedia();
          }
        });

        this.session.invite()
          .then(() => {
            console.log('Invite sent');
          })
          .catch((err) => {
            console.error('Error inviting session', err);
          });
      }
    },
    answerCall() {
      if (this.session && this.incomingCall) {
        this.connectingCall = true; // Set to true when answering the call
        this.session.accept().then(() => {
          this.callAnswered = true;
          // Media will be handled when the call is established
        }).catch(err => {
          console.error('Failed to accept call', err);
          this.connectingCall = false; // Reset if there was an error accepting the call
        });
        this.incomingCall = false;
      }
    },
    hangupCall() {
      if (this.session) {
        switch(this.session.state) {
          case SIP.SessionState.Initial:
          case SIP.SessionState.Establishing:
            if (this.session instanceof SIP.Inviter) {
              this.session.cancel();
            } else {
              this.session.reject();
            }
            break;
          case SIP.SessionState.Established:
            this.session.bye();
            break;
          case SIP.SessionState.Terminating:
          case SIP.SessionState.Terminated:
            break;
          default:
            console.error("Unhandled session state");
            break;
        }
        this.resetCallState();
      }
    },
    resetCallState() {
      this.session = null;
      this.incomingCall = false;
      this.callAnswered = false;
      this.callActive = false;
      this.connectingCall = false; // Reset connecting state
      this.callDuration = null;
      this.callerId = null;
      this.callerName = null;
      this.formattedCallerId = "";
      this.showKeypad = false;
      clearTimeout(this.callTimeout);
      clearInterval(this.callTimer);
      this.collapseIfNotAnswered();
    },
    collapseIfNotAnswered() {
      if (!this.callAnswered && this.expanded) {
        this.expanded = false;
        this.$refs.phoneContainer.style.height = '3rem';
      }
    },
    startCallTimeout() {
      this.callTimeout = setTimeout(() => {
        if (this.incomingCall && !this.callAnswered) {
          this.hangupCall();
          this.collapseIfNotAnswered();
        }
      }, 30000); // 30 seconds timeout
    },
    startCallTimer() {
      this.callStartTime = Date.now();
      this.callTimer = setInterval(() => {
        this.callDuration = Date.now() - this.callStartTime;
      }, 1000);
    },
    formatDuration(ms) {
      const totalSeconds = Math.floor(ms / 1000);
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
    parseCallerId(uri) {
      const matches = uri.match(/sip:([^@]*)/);
      return matches ? matches[1] : uri;
    },
    formatPhoneNumber(phoneNumber) {
      // Assuming phone number is in the format of +15039139051
      const cleaned = ('' + phoneNumber).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
      }
      return phoneNumber;
    },
    muteCall() {
      console.log("Call muted");
    },
    toggleKeypad() {
      this.showKeypad = !this.showKeypad;
    }
  },
};
</script>

<style scoped>
.btn {
  @apply p-4 bg-gray-200 text-center text-xl rounded-full;
}
</style>
