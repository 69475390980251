<template>
  <div class="content-container">
    <div class="flex flex-row w-1/3">
      <div class="flex flex-col w-full">
        <img class="h-20 object-contain" src="/assets/logo.png" alt="logo" />
        <h1 class="text-2xl mb-2">Contact Us</h1>
        <h4 class="text-lg mb-4">Contact us to Sign Up!</h4>
        <form @submit.prevent="performContactUs" class="space-y-4">
          <div>
            <label for="fullName" class="block text-sm">Full Name</label>
            <input id="fullName" type="text" v-model="account.fullName" @blur="validateFullName" :class="fullNameError ? 'border-yellow-500' : ''" class="w-full rounded border px-2 py-1" />
            <div v-if="fullNameError" class="txt-custom-color">{{ fullNameError }}</div>
          </div>
          <div>
            <label for="email" class="block text-sm">Email</label>
            <input id="email" type="email" v-model="account.email" @blur="validateEmail" :class="emailError ? 'border-yellow-500' : ''" class="w-full rounded border px-2 py-1" />
            <div v-if="emailError" class="txt-custom-color">{{ emailError }}</div>
          </div>
          <div>
            <label for="phone" class="block text-sm">Phone</label>
            <input id="phone" type="text" v-model="account.phone" @blur="validatePhone" :class="phoneError ? 'border-yellow-500' : ''" class="w-full rounded border px-2 py-1" />
            <div v-if="phoneError" class="txt-custom-color">{{ phoneError }}</div>
          </div>
          <div>
            <label for="message" class="block text-sm">Message</label>
            <textarea id="message" v-model="account.message" @blur="validateMessage" :class="messageError ? 'border-yellow-500' : ''" class="w-full rounded border px-2 py-1"></textarea>
            <div v-if="messageError" class="txt-custom-color">{{ messageError }}</div>
          </div>
          <div v-if="apiError" class="text-red-500 mb-4">{{ apiError }}</div>
          <button class="w-full text-white rounded px-4 py-2 bg-tvBlue" :disabled="isContactUsing">Contact Us</button>
        </form>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
import AccountService from "@/services/AccountService";

export default {
  name: 'ContactUs',
  data() {
    return {
      account: { fullName: '', email: '', phone: '', message: '' },
      fullNameError: '', emailError: '', phoneError: '', messageError: '', apiError: '', isContactUsing: false
    };
  },
  methods: {
    validateFullName() { this.fullNameError = this.account.fullName.split(' ').length < 2 ? 'Full name must contain at least a first and last name' : ''; },
    validateEmail() { this.emailError = /\S+@\S+\.\S+/.test(this.account.email) ? '' : 'Invalid email address'; },
    validatePhone() { this.phoneError = /^(\d{3}-\d{3}-\d{4}|\d{10})$/.test(this.account.phone) ? '' : 'Invalid phone format. Use 000-000-0000'; },
    validateMessage() { this.messageError = this.account.message.length < 1 ? 'You must enter a message for the admin.' : ''; },
    async performContactUs() {
      this.validateFullName(); this.validateEmail(); this.validatePhone(); this.validateMessage();
      if (!this.fullNameError && !this.emailError && !this.phoneError && !this.messageError) {
        try {
          this.isContactUsing = true;
          const success = await AccountService.contactUs(this.account);
          if (success) this.$router.push({ path: '/login', query: {message:'Your Contact Us form request was received!'} });
        } catch (error) { this.apiError = 'Unknown error occurred, please try again later.'; }
        finally { this.isContactUsing = false; }
      }
    }
  }
};
</script>

<style>
.v-enter-active, .v-leave-active {
  transition: opacity 3s;
}
.v-enter, .v-leave-to {
  opacity: 0;
}

.btn-custom-color {
  background-color: #fbbb05; /* Your desired color */
  color: white; /* Set text color to ensure visibility */
}

.txt-custom-color {
  color: #fbbb05; /* Set text color to ensure visibility */
}


.btn-custom-color:hover {
  background-color: #e0a800; /* Slightly darker for hover effect */
}

.login-custom-color {
  background-color: #696969; /* Your desired color */
  color: white; /* Set text color to ensure visibility */
}


.login-custom-color:hover {
  background-color: #4c4c4c; /* Slightly darker for hover effect */
}


/* Add styles for the disabled state */
.btn-custom-color:disabled {
  background-color: #fbbb05; /* Maintain the background color */
  color: white; /* Maintain the text color */
  opacity: 0.7; /* Optional: Lower opacity to indicate it's disabled */
  cursor: not-allowed; /* Optional: Change cursor to indicate it's not clickable */
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  height: 100vh; /* Full viewport height */
  position: relative; /* Ensure it's positioned above the background */
  z-index: 1; 
}

</style>