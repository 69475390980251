import axios from 'axios';

const state = {
  roles: []
};

const mutations = {
  setRoles(state, roles) {
    state.roles = roles;
  }
};

const actions = {
  async fetchRoles({ commit }) {
    try {
      const response = await axios.get('/user/roles');
      commit('setRoles', response.data);
    } catch (error) {
      console.error('Failed to fetch roles:', error);
    }
  }
};

const getters = {
  hasRole: (state) => (role) => {
    return state.roles.some(r => r.name === role);
  }
};

export default {
  namespaced: true,  // Ensure the module is namespaced
  state,
  mutations,
  actions,
  getters
};
