import { createStore } from 'vuex';
import auth from './modules/auth';
import roles from './modules/roles';

export default createStore({
  state: {
    isLoading: false,
  },
  mutations: {
    setLoading(state, payload) {
      state.isLoading = payload;
    }
  },
  modules: {
    auth,
    roles
  }
});
