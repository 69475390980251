<template>
  <div class="backimg"></div>
  <div class="content-container">
    <div class="max-w-xs mx-auto">
      <img class="h-32 object-contain mx-auto mb-4" alt="logo" src="/assets/logo.png">
      <h1 class="text-2xl font-bold mb-4 text-center">Forgot Password</h1>
      <h4 class="text-lg mb-8 text-center text-gray-600">Request a Password Reset Link</h4>
      <form class="space-y-4">
        <div>
          <label for="email" class="block text-sm font-bold text-gray-700">Email</label>
          <input
            id="email"
            type="email"
            placeholder="Enter your email"
            v-model="account.email"
            @blur="validateEmail"
            :class="{'border-red-500': emailError}"
            class="w-full p-3 rounded-lg border border-gray-300 bg-gray-100 focus:bg-white focus:border-gray-400 shadow-sm"
          />
          <div v-if="emailError" class="text-red-500 text-xs mt-1">{{ emailError }}</div>
        </div>
        <button
          @click="performLogin"
          :disabled="isLoggingIn"
          class="w-full bg-orange-500 text-white p-3 rounded-lg shadow-md hover:bg-orange-600"
        >
          Submit
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import AccountService from '@/services/AccountService';

export default {
  name: 'ForgotPassword',
  data() {
    return {
      account: {
        email: ''
      },
      emailError: '',
      isLoggingIn: false,
      apiError: ''
    };
  },
  methods: {
    validateEmail() {
      const re = /\S+@\S+\.\S+/;
      if (!re.test(this.account.email)) {
        this.emailError = 'Invalid email address';
      } else {
        this.emailError = '';
      }
    },
    async performLogin() {
      this.isLoggingIn = true;
      this.apiError = '';
      this.validateEmail();
      if (this.emailError) {
        this.isLoggingIn = false;
        return;
      }
      try {
        const success = await AccountService.forgotPassword(this.account);
        if (success) {
          this.$router.push({ path: '/login', query: { message: 'An email to reset your password has been sent!' } });
        }
      } catch (error) {
        alert('Unknown error occurred, please try again later.');
        this.apiError = 'Unknown error occurred, please try again later.';
      } finally {
        this.isLoggingIn = false;
      }
    }
  }
};
</script>

<style scoped>
.v-enter-active, .v-leave-active {
  transition: opacity 3s;
}
.v-enter, .v-leave-to {
  opacity: 0;
}

.backimg {
  opacity: 0.30;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background: url('~@/assets/swirl_crop.jpg') no-repeat center center;
  background-color: #FFF;
  background-size: cover;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  z-index: 1;
}
</style>
