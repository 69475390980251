<template>
  <div class="backimg"> </div>
  <div>
    <Navigation v-if="hasLaravelToken" />
    <RouterView class="router-view-content" />
    <PhoneComponent v-if="hasLaravelToken" /> <!-- Always keep PhoneComponent active -->
  </div>
</template>

<script>
import { ref } from 'vue';
import Navigation from './views/components/Navigation.vue';
import PhoneComponent from './views/components/PhoneComponent.vue';

export default {
  setup() {
    // Check if there is a Laravel token in local storage
    const hasLaravelToken = ref(localStorage.getItem('token') ? true : false);
    
    return {
      hasLaravelToken
    };
  },
  components: { Navigation, PhoneComponent }
};
</script>

<style scoped>
.backimg {
  opacity: 0.50;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background: url('~@/assets/swirl_crop.jpg') no-repeat center center;
  background-color: #FFF;
  background-size: cover;
}

.router-view-content {
  min-height: calc(100vh - 65px);
  width: 100%;
}
</style>
